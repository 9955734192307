import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { addAuthentication } from '../../../components/addAuthentication';
import {
  DashboardPageHeading,
  DashboardUserList,
} from '../../../components/dashboard';
import { DashboardLayout } from '../../../components/layout';
import { StateContext } from '../../../lib/providers';
import './scss/Users.scss';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default addAuthentication(() => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amwell Private Practice | Dashboard - Users</title>
    </Helmet>
    <DashboardLayout>
      <div className="UsersPage">
        <DashboardUsersPageHeading />
        <div className="UsersPage-container">
          <DashboardUserList />
        </div>
      </div>
    </DashboardLayout>
  </>
));

const DashboardUsersPageHeading = () => {
  const state = useContext(StateContext);
  const { user } = state || {};
  const { providerAccounts, subscription } = user || {};
  const { addOns } = subscription || {};
  const totalSeats = addOns ? addOns[0].quantity : 0;
  const totalActive = providerAccounts ? providerAccounts.length : 0;

  return (
    <DashboardPageHeading
      heading={'My Telehealth Practice Users'}
      links={[
        {
          label: `Active Users (${totalActive} of ${totalSeats})`,
          to: '/dashboard/users',
        },
      ]}
    />
  );
};
